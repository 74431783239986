import MenuMainView from "../views/MenuMain";

import { ecommerceStoreId, apiPath } from "../constants";

import { dataSourceV2 } from "../../../commons-ui/core/DataSource";

export default MenuMainView;

const requestProductList = async (category) => {
  const query = {
    size: 100,
    sort: [],
    _source: ["search_result_data"],
    query: {
      bool: {
        must: [],
        filter: [{ term: { "category.id_list": category } }],
        must_not: [{ term: { "hidden.id_list": "default" } }],
      },
    },
  };

  return dataSourceV2(
    `ecommerce_product_view_list?is_search=true&postprocess=default&query=${JSON.stringify(
      query
    )}`,
    { url: apiPath }
  ).then((v) => {
    if (!v.message && v.item_list) {
      const itemList = v.item_list.map((data) => {
        const category_id_dict = data.category_id_dict || {};
        const version = data.version || {};

        return {
          ...version,
          category_id_dict,
          attribute_set_id: data.attribute_set_id,
          ecommerce_product_id: data.ecommerce_product_id,
        };
      });
      return itemList;
    }
    return [];
  });
};

export const getStaticProps = async () => {
  const categoryListResponse = await dataSourceV2(
    `ecommerce_product_category_list?ecommerce_store_id=${ecommerceStoreId}`,
    { url: apiPath }
  );

  const categoryList = (categoryListResponse.item_list || []).filter(
    (category) => {
      return (
        !category.is_hidden &&
        (!category.type ||
          category.type === "primary" ||
          category.type === "preview")
      );
    }
  );

  const productDictByCategory = {};

  for (let i = 0; i < categoryList.length; i++) {
    const categoryId = categoryList[i].ecommerce_product_category_id;

    const productList = await requestProductList(categoryId);
    productDictByCategory[categoryId] = productList;
  }

  const storeSettingsResponse = await dataSourceV2(
    `ecommerce_store_settings_get?ecommerce_store_id=${ecommerceStoreId}`,
    { url: apiPath }
  );

  const giftCardCategory = {
    name: "GIFT CARDS",
    ecommerce_product_category_id: "__gift_card_category"
  };
  categoryList.push(giftCardCategory);
  const products = [
    {name: "eGift Card", price: 10, price_prefix: "from", redirect: "/gift-card-order", images: "/static/img/egift_card.png"},
    {name: "Plastic Gift Card", price: 10, price_prefix: "from", redirect: "/gift-card-order?gift_card_type=plastic", images: "/static/img/gift_card.png"}
  ];
  productDictByCategory["__gift_card_category"] = products;

  return {
    props: {
      categoryList: categoryList || [],
      productDictByCategory: productDictByCategory || {},
      storeSettings: storeSettingsResponse.item || {},
      ecommerceStoreId: ecommerceStoreId
    },
    revalidate: 600,
  };
};
